<template>
  <div>
    <div class="register-profile-wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-6 col-xl-5 p-0">
            <div class="form-wrapper left-panel position-relative">
              <div class="logo">
                <router-link
                  :to="{ name: 'anonymousRoute-home' }"
                  class="logo-image"
                >
                  <img
                    src="/img/digital-staging-logo.svg"
                    alt="Digital Staging"
                  />
                </router-link>
              </div>
              <div
                class="register-profile-banner-mobile d-xs-block d-sm-block d-md-block d-lg-none"
              >
                <img src="/img/login-banner-mobile.png" alt="Sign up Banner" />
              </div>
              <div class="register-wrapper">
                <div class="register position-relative">
                  <h2 class="grey-text">プロファイル登録</h2>
                  <form method="POST" v-on:submit="registerProfile">
                    <div class="row">
                      <div class="col-12 mt-4">
                        <b-form-group>
                          <p class="m-0 grey-text">アカウントのタイプ</p>
                          <b-form-radio-group
                            v-model="accountType"
                            name="account_type"
                          >
                            <b-form-radio value="1">法人</b-form-radio>
                            <b-form-radio value="2">個人</b-form-radio>
                          </b-form-radio-group>
                        </b-form-group>
                      </div>
                      <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="mt-4">
                          <p class="m-0 grey-text">名前 (姓)</p>
                          <input
                            id="first-name"
                            class="form-control shadow-1"
                            type="text"
                            minlength="1"
                            maxlength="100"
                            v-model="lastName"
                            v-on:keyup="formMessage = null"
                            placeholder="名前（姓）を入力してください"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="mt-4">
                          <p class="m-0 grey-text">名前 (名)</p>
                          <input
                            id="last-name"
                            class="form-control shadow-1"
                            type="text"
                            minlength="1"
                            maxlength="100"
                            v-model="firstName"
                            v-on:keyup="formMessage = null"
                            placeholder="名前（名）を入力してください"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="mt-4">
                          <p class="m-0 grey-text">法人名</p>
                          <input
                            id="company-name"
                            class="form-control shadow-1"
                            type="text"
                            v-model="companyName"
                            placeholder="法人名を入力してください"
                          />
                        </div>
                      </div>
                      <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="mt-4">
                          <p class="m-0 grey-text">電話番号</p>
                          <input
                            id="tel"
                            class="form-control shadow-1"
                            type="text"
                            v-model="tel"
                            placeholder="電話番号を入力してください"
                          />
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="mt-4">
                          <p class="m-0 grey-text">生年月日</p>
                          <v-date-picker
                            v-model="birthDate"
                            :masks="{ L: 'YYYY-MM-DD' }"
                            locale="ja"
                            mode="date"
                            :max-date="new Date()"
                          >
                            <template v-slot="{ inputValue, togglePopover }">
                              <div class="date-picker-wrap">
                                <input
                                  :value="inputValue"
                                  v-on:click="togglePopover"
                                  class="form-control datepicker-input shadow-1"
                                  placeholder="YYYY-MM-DD"
                                />
                              </div>
                            </template>
                          </v-date-picker>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="mt-4">
                          <p class="m-0 grey-text">性別</p>
                          <b-form-select
                            class="shadow-1"
                            v-model="gender"
                            :options="getGender()"
                          ></b-form-select>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="mt-4">
                          <p class="m-0 grey-text">
                            <span>都道府県</span>
                            <b-spinner
                              small
                              v-if="prefectures.loader"
                            ></b-spinner>
                          </p>
                          <b-form-select
                            class="shadow-1"
                            v-model="prefectures.value"
                            :options="prefectures.options"
                          ></b-form-select>
                        </div>
                      </div>
                      <div class="col-12">
                        <b-alert
                          class="m-0 mt-3 light-grey-outline grey-text"
                          variant="warning"
                          show
                          v-if="formMessage !== null"
                        >
                          {{ formMessage }}
                        </b-alert>
                      </div>
                      <div class="col-12 mt-5">
                        <button
                          class="btn btn-ds dark-blue white-text"
                          type="submit"
                          v-waves.light
                        >
                          保存
                        </button>
                        <button
                          class="btn btn-ds light-grey white-text mt-3"
                          type="button"
                          v-waves.light
                          v-on:click="skipRegisterProfile"
                        >
                          スキップ
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-12 col-lg-6 col-xl-7 p-0 d-none d-lg-block banner-wrap"
          >
            <img src="/img/login-banner.png" alt="Sign up Banner" />
          </div>
        </div>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<script>
// Import Vuex/Store
import { mapGetters } from 'vuex';

// Import Services
import {
  globalService_RegisterProfile,
  globalService_GetAllPrefectures,
} from '../services/global';

// Import Moment JS
import moment from 'moment';

// Import Vue Calendar
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

// Important Custom Components
import mainFooter from '../components/customer/Main-Footer.vue';

//import common
import { getGender } from '../common/Utility';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Sign Up',
    };
  },

  components: {
    'v-date-picker': DatePicker,
    'main-footer': mainFooter,
  },

  data() {
    return {
      accountType: 1,
      firstName: null,
      lastName: null,
      companyName: null,
      tel: null,
      birthDate: null,
      gender: null,
      prefectures: {
        value: null,
        options: [],
        loader: true,
      },
      formMessage: null,
    };
  },

  computed: {
    ...mapGetters(['currentUser', 'isLoggedIn']),
  },

  mounted() {
    this.setDefaultBirthDate();
    this.retievePrefectures();
    this.setUserProfile();
  },

  methods: {
    getGender,
    setUserProfile() {
      if (this.currentUser.user_profile) {
        this.firstName = this.currentUser.user_profile.first_name;
        this.lastName = this.currentUser.user_profile.last_name;
        this.companyName = this.currentUser.user_profile.company_name;
        this.tel = this.currentUser.user_profile.tel;
        this.birthDate = this.currentUser.user_profile.birth_date;
        this.gender = this.currentUser.user_profile.gender;
        this.prefectures.value = this.currentUser.user_profile.prefectures;
      }
    },
    setDefaultBirthDate() {
      // set default date today - 20yrs
      var date = new Date();
      date.setDate(date.getDate() - 6);
      date.setFullYear(date.getFullYear() - 20);
      this.birthDate = date;
    },

    retievePrefectures() {
      let _this = this;

      _this.prefectures.loader = true;

      globalService_GetAllPrefectures()
        .then((response) => {
          let data = response.data;

          let prefectures = [];
          prefectures.push({
            value: null,
            text: '都道府県を選択してください',
            disabled: true,
          });

          data.forEach((el) => {
            prefectures.push({
              value: el.id,
              text: el.name,
            });
          });

          _this.prefectures.options = prefectures;

          _this.prefectures.loader = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    formatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },

    registerProfile(e) {
      let _this = this;

      e.preventDefault();

      _this.$store.state.modalLoaderMessage = '保存中';
      _this.$bvModal.show('modal-loader');

      globalService_RegisterProfile({
        id: _this.currentUser.id,
        account_type: _this.accountType,
        first_name: _this.firstName,
        last_name: _this.lastName,
        company_name: _this.companyName,
        tel: _this.tel,
        gender: _this.gender,
        birth_date: this.formatDate(_this.birthDate),
        prefectures: _this.prefectures.value,
      })
        .then(() => {
          _this.$router.push({
            name: 'anonymousRoute-register-questionnaire',
          });

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 1500);
        })
        .catch((error) => {
          console.log(error);

          _this.formMessage = 'アカウントの作成中にエラーが起こりました。';

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 500);
        });
    },

    skipRegisterProfile() {
      let _this = this;
      _this.$router.push({
        name: 'anonymousRoute-register-questionnaire',
      });

      setTimeout(() => {
        _this.$bvModal.hide('modal-loader');
      }, 1500);
    },
  },
};
</script>

<style scoped>
.hints ul {
  list-style: none;
  padding: 0;
  font-size: 12px;
}

.hint-enter {
  opacity: 0;
  transform: translate3d(-20px, 0, 0);
}

.hint-leave-to {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
}

.hint-enter-active {
  transition: 300ms;
}

.hint-leave-active {
  transition: 400ms;
}

.register-profile-wrapper .form-wrapper {
  padding: 42px;
  height: 100vh;
}

@media (max-width: 991.98px) {
  .register-profile-wrapper .form-wrapper {
    height: auto;
  }
}

.register-profile-wrapper .form-wrapper .lets-start {
  background-color: #00adee;
  color: #fff;
  border-radius: 25px;
  position: absolute;
  right: -178px;
  padding: 15px 12px;
  width: 175px;
  text-align: center;
  height: 53px;
  margin: 5px 0;
}

.register-profile-wrapper .form-wrapper .register-wrapper {
  padding: 40px 40px 10px 140px;
}

.register-profile-wrapper .form-wrapper .register-wrapper .title-form {
  font-weight: 500;
}

.register-profile-wrapper .form-wrapper .register-wrapper form .form-group {
  position: relative;
}

.register-profile-wrapper .form-wrapper .register-wrapper form .btn-google {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #bfbebe;
  border-radius: 100px;
  color: #707070;
}

.register-profile-wrapper
  .form-wrapper
  .register-wrapper
  form
  .option-links
  p
  a,
.register-profile-wrapper .form-wrapper .register-wrapper form .option-links a {
  color: #034ea1;
  text-decoration: underline !important;
}

.register-profile-wrapper .form-wrapper .form-footer {
  padding: 10px 40px 10px 140px;
  position: absolute;
  margin: 1em auto;
  overflow: hidden;
  bottom: 0;
  width: 80%;
  margin: auto;
  left: 0;
  bottom: 0;
  right: 0;
}

.register-profile-wrapper .form-wrapper .form-footer ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -1px;
  list-style: none;
  padding-left: 0;
}

.register-profile-wrapper .form-wrapper .form-footer ul li {
  flex-grow: 1;
  flex-basis: auto;
  margin: 0.25em 0;
  padding: 0 1em;
  text-align: center;
  border-left: 1px solid #ccc;
}

.register-profile-wrapper .form-wrapper .form-footer ul li:first-child {
  border-left: 0 !important;
}

.register-profile-wrapper .form-wrapper .form-footer ul li a {
  text-decoration: underline !important;
  color: #9b9b9b;
}

.register-profile-wrapper .banner-wrap {
  height: 100vh;
  overflow: hidden;
  padding: 0;
}

@media only screen and (max-height: 850px) {
  .register-profile-wrapper .banner-wrap {
    height: 120vh;
  }
}

@media only screen and (max-height: 720px) {
  .register-profile-wrapper .banner-wrap {
    height: 150vh;
  }
}

.register-profile-wrapper .banner-wrap img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: left center;
  object-position: left center;
}

@media (max-width: 991.98px) {
  .register-profile-wrapper .form-wrapper {
    padding: 0 !important;
  }

  .register-profile-wrapper .form-wrapper .logo {
    padding: 20px;
  }

  .register-profile-wrapper .form-wrapper .logo .logo-image img {
    width: 172px;
  }

  .register-wrapper .form-wrapper .logo .lets-start {
    right: 20px !important;
    padding: 8px 10px;
    height: auto;
    margin: 0;
  }

  .register-profile-wrapper .form-wrapper .register-wrapper {
    padding: 20px;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    position: relative;
    top: -35px;
    background: #fff;
  }

  .register-profile-wrapper .form-wrapper .form-footer {
    padding: 20px;
    position: relative !important;
    width: 100%;
  }

  .register-wrapper .form-wrapper .register-profile-banner-mobile img {
    width: 100%;
    height: 251px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .register-profile-wrapper .form-wrapper {
    padding: 10px 10px 10px 42px !important;
  }
  .register-profile-wrapper .form-wrapper .register-wrapper,
  .register-profile-wrapper .form-wrapper .form-footer {
    padding: 40px 10px 10px 0 !important;
  }
}

@media (min-width: 992px) and (max-width: 1399.98px) {
  .register-profile-wrapper .form-wrapper {
    padding: 10px 10px 10px 42px !important;
  }
  .register-profile-wrapper .form-wrapper .register-wrapper,
  .register-profile-wrapper .form-wrapper .form-footer {
    padding: 40px 10px 10px 40px !important;
  }
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 0.5px solid #b8b8b8;
}

.separator:not(:empty)::before {
  margin-right: 8px;
}

.separator:not(:empty)::after {
  margin-left: 8px;
}
</style>
